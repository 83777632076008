<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_4" x1="36.27" x2="158.73" y1="91.5" y2="91.5">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <g id="icon-ICT">
      <rect :fill="background" height="183" width="183"/>
      <path d="M128.09,50.69V82.21a35.28,35.28,0,0,0-10.2,3.66V50.69H56.68V91.5h53.55a35.51,35.51,0,0,0-7.39,10.2H56.61A10.16,10.16,0,0,1,46.47,91.5V50.62A10.17,10.17,0,0,1,56.68,40.49H118A10.16,10.16,0,0,1,128.09,50.69ZM72,120.84v7.65H92.39v-7.65Zm30.8,11.47H66.88V117H99.35a35.4,35.4,0,0,1,1.51-10.2H46.47a10.17,10.17,0,0,0-10.2,10.13v15.37a10.17,10.17,0,0,0,10.14,10.2H110.1A35.37,35.37,0,0,1,102.78,132.31Zm55.47-8.94a1.29,1.29,0,0,1,.3,1.63l-5.09,8.81a1.29,1.29,0,0,1-1.55.56l-6.34-2.58a18.37,18.37,0,0,1-4.3,2.52l-.94,6.75a1.3,1.3,0,0,1-1.27,1.07H128.87a1.3,1.3,0,0,1-1.27-1.07l-.94-6.75a16.89,16.89,0,0,1-3.6-2c-.24-.16-.47-.34-.7-.52l-1.28.52L116,134.37a1.28,1.28,0,0,1-1.55-.56l-.87-1.5-2.2-3.82-2-3.49a1.24,1.24,0,0,1,.3-1.63l3.21-2.53,2.16-1.7c-.09-.71-.15-1.42-.17-2.13,0-.11,0-.23,0-.34a21.53,21.53,0,0,1,.18-2.54L109.68,110a1.26,1.26,0,0,1-.31-1.63l.89-1.54,3-5.11,1.25-2.16A1.24,1.24,0,0,1,116,99l5.32,2.14,1,.41a18.3,18.3,0,0,1,4.3-2.5l.43-3.09.51-3.65a1.24,1.24,0,0,1,.49-.79h0a1.21,1.21,0,0,1,.78-.27h10.19a1.3,1.3,0,0,1,1.27,1.07l.94,6.74a18.3,18.3,0,0,1,4.3,2.5L151.91,99a1.24,1.24,0,0,1,1.55.56l5.1,8.81a1.28,1.28,0,0,1-.31,1.63l-5.37,4.15a21.53,21.53,0,0,1,.18,2.54,20.37,20.37,0,0,1-.18,2.47Zm-15.37-6.7a8.91,8.91,0,0,0-8.91-8.91,8.54,8.54,0,0,0-1.34.1,8.9,8.9,0,0,0-7.57,8.81c0,.11,0,.23,0,.34a8.91,8.91,0,0,0,17.81-.34Z" style="fill:url(#Naamloos_verloop_4);"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>